import React, { lazy, Suspense, useEffect } from "react";

// for custom designing carousel indicators
import "./Collaborations.css";

import Carousel from "react-bootstrap/Carousel";
import IconButton from "@mui/material/IconButton";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

import { Navbar } from "../../components/Navbar/Navbar";
import { useLocation, useNavigate } from "react-router-dom";

// import CollaborationBanner from "../../components/Collaborations/Collaborations Banner/CollaborationBanner";
// import RevenueModel from "../../components/Collaborations/Revenue Model/RevenueModel";
// import Awards from "../../components/Collaborations/Awards/Awards";
// import CollaborateWithUs from "../../components/Collaborations/Collaborate with us/CollaborateWithUs";

import { Maintenance } from "../../components/Maintenance/Maintenance";
import { ChatState } from "../../context/Context";
import Loading from "../../components/Loading/Loading";

const CollaborationBanner = lazy(() =>
  import(
    "../../components/Collaborations/Collaborations Banner/CollaborationBanner"
  )
);
const RevenueModel = lazy(() =>
  import("../../components/Collaborations/Revenue Model/RevenueModel")
);
const Awards = lazy(() =>
  import("../../components/Collaborations/Awards/Awards")
);
const CollaborateWithUs = lazy(() =>
  import(
    "../../components/Collaborations/Collaborate with us/CollaborateWithUs"
  )
);

export const Collaboration = () => {
  const Navigate = useNavigate();
  const location = useLocation();
  const { underMaintenance, setUnderMaintenance } = ChatState();

  // this resizeListener will show undermaintenance for all mobile views
  useEffect(() => {
    const resizeListener = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;
      if (width < 376) {
        setUnderMaintenance(true);
      } else if (width > 2045) {
        setUnderMaintenance(true);
      } else {
        setUnderMaintenance(false);
      }
    };

    resizeListener();
    window.addEventListener("resize", resizeListener);
    return () => window.removeEventListener("resize", resizeListener);
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  }, [location]);

  return (
    <>
      {underMaintenance && <Maintenance />}
      {!underMaintenance && (
        <Suspense fallback={<Loading />}>
          <div>
            {/* ---- banner Component ---- */}
            <CollaborationBanner />
            <RevenueModel />
            <CollaborateWithUs />
            <Awards />
          </div>
        </Suspense>
      )}
    </>
  );
};
