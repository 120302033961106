import React, { lazy, Suspense, useEffect } from "react";
import styles from "./blog.module.css";
import { useLocation, useNavigate } from "react-router-dom";
// import Blog1 from "../../components/Blogs/Blog1/Blog1";
// import Blog2 from "../../components/Blogs/Blog2/Blog2";
// import Blog3 from "../../components/Blogs/Blog3/Blog3";
// import Blog4 from "../../components/Blogs/Blog4/Blog4";
import { ChatState } from "../../context/Context";
import { Maintenance } from "../../components/Maintenance/Maintenance";
import Loading from "../../components/Loading/Loading";

const Blog1 = lazy(() => import("../../components/Blogs/Blog1/Blog1"))
const Blog2 = lazy(() => import("../../components/Blogs/Blog2/Blog2"))
const Blog3 = lazy(() => import("../../components/Blogs/Blog3/Blog3"))
const Blog4 = lazy(() => import("../../components/Blogs/Blog4/Blog4"))

const Blog = () => {
  const location = useLocation();
  const { underMaintenance, setUnderMaintenance } = ChatState();

  // this resizeListener will show undermaintenance for all mobile views
  useEffect(() => {
    const resizeListener = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;
      if (width < 376) {
        setUnderMaintenance(true);
      } else if (width > 2045) {
        setUnderMaintenance(true);
      } else {
        setUnderMaintenance(false);
      }
    }

    resizeListener()
    window.addEventListener("resize", resizeListener);
    return () => window.removeEventListener("resize", resizeListener);
  }, [])
  
  const Navigate = useNavigate();
  const goBack = () => {
    Navigate({ pathname: "/" });
  };
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'instant'
    });
  }, [location])
  return (
    <>
      {underMaintenance && <Maintenance />}
      {!underMaintenance &&
        <Suspense fallback={<Loading />}>

          <div className={styles.main__container}>
            <div className={styles.container__1}>
              {/* ----------  Title  ------------- */}
              <div className={styles.title__container}>
                <h1
                  className={`${styles.montserrat__bold} ${styles.font__50} ${styles.container__1__title}`}
                >
                  Blogs
                </h1>
                <button type="submit" onClick={goBack} className={styles.custom__back__button}>
                  <img draggable={false} src={`${process.env.PUBLIC_URL}/Additionals/back.png`} alt="back button" />
                </button>
              </div>
              <Blog1 />
            </div>

            <div className={styles.container__2}>
              <h4 className={`${styles.montserrat__bold} ${styles.container__2__title}`}>
                All About Maternal & Child Health Care And Family Planning
              </h4>
              <Blog2 />
            </div>

            <div className={styles.container__3}>
              <h4 className={`${styles.montserrat__bold} ${styles.container__3__title}`}>
                Mental Health for Teenagers
              </h4>
              <Blog3 />
            </div>

            <div className={styles.container__4}>
              <h4 className={`${styles.montserrat__bold} ${styles.container__4__title}`}>
                Mental Health for Working Professionals
              </h4>
              <Blog4 />
            </div>
          </div>
        </Suspense>
      }
    </>
  );
};

export default Blog;
