import React, { useEffect, useState } from 'react'
import styles from './Maintenance.module.css'

export const Maintenance = () => {
  const [large, setLarge] = useState(false);
  useEffect(() => {
    const resizeHandler = () => {
      if (window.innerWidth > 2045) {
        setLarge(true);
      } else {
        setLarge(false);
      }
    }
    resizeHandler();
    window.addEventListener("resize" , resizeHandler);
    return () => {
      window.removeEventListener("resize" , resizeHandler);
    }
  }, [])
  return (
    <>
      <div className={styles.topNavSection}>
        <img src={"/Navbar-logos/Title_icon.png"} className={styles.logo}/>
        <h5 className={styles.companyName}>Thetalabs AI</h5>
      </div>
      {!large && <div className={styles.container}>
        <h2>This website is viewable in Tablets, Laptops and bigger screens.</h2>
      </div>}
      {large && <div className={styles.container}>
        <h2>Please View this Website below Screens of your current System</h2>
      </div>}
    </>
  )
}
