import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./ContactUsForm.module.css";
import axios from "axios";
import { responsiveFontSizes } from "@mui/material";
import Loader from "react-js-loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ChatState } from "../../context/Context";
import { Maintenance } from "../../components/Maintenance/Maintenance";

const ContactForm = () => {
  const { underMaintenance, setUnderMaintenance } = ChatState();

  // this resizeListener will show undermaintenance for all mobile views
  useEffect(() => {
    const resizeListener = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;
      if (width < 376) {
        setUnderMaintenance(true);
      } else if (width > 2045) {
        setUnderMaintenance(true);
      } else {
        setUnderMaintenance(false);
      }
    };

    resizeListener();
    window.addEventListener("resize", resizeListener);
    return () => window.removeEventListener("resize", resizeListener);
  }, []);

  const Navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    companyName: "",
    phoneNumber: "",
    email: "",
    address: "",
    purpose: "",
    city: "",
    state: "",
    zip: "",
  });
  const goBack = () => {
    Navigate({ pathname: "/" });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoader(true);
    // handle the form
    // Further validation for required fields
    const requiredFields = [
      "firstName",
      "lastName",
      "phoneNumber",
      "email",
      "address",
      "purpose",
      "city",
      "state",
      "zip",
    ];
    for (let field of requiredFields) {
      if (!data[field]) {
        alert(`${field} is required.`);
        setLoader(false);
        return;
      }
    }

    // Further validation if necessary
    if (data.phoneNumber.length !== 10) {
      alert("Phone number must be exactly 10 digits.");
      setLoader(false);
      return;
    }

    if (data.zip.length > 6) {
      alert("Zip code must be a maximum of 6 characters.");
      setLoader(false);
      return;
    }
    try {
      const response = await axios.post(
        `https://thetalabs.ai/nw/api/v1/post/contactUs`,
        data
      );
      if (response.data.status == 200) {
        toast(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          type: "success",
          theme: "light",
        });
        setData({
          firstName: "",
          lastName: "",
          companyName: "",
          phoneNumber: "",
          email: "",
          address: "",
          purpose: "",
          city: "",
          state: "",
          zip: "",
        });
      } else {
        toast(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          type: "error",
          theme: "light",
        });
      }
      setLoader(false);
    } catch (e) {
      toast(e.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        type: "error",
        draggable: false,
        progress: undefined,
        theme: "light",
      });
      console.log("Found error : ", e.message);
      setLoader(false);
    }
  };
  return (
    <>
      <div className={styles.Landscapeview}>
        {underMaintenance && <Maintenance />}
        {!underMaintenance && (
          <div style={{ position: "relative" }}>
            <ToastContainer />
            {loader && (
              <div className={styles.loaderContainer}>
                <Loader
                  type="spinner-cub"
                  bgColor={"rgba(0, 0, 0, 0.8)"}
                  color={"white"}
                  title={"Please Wait"}
                  size={100}
                />
              </div>
            )}
            <div className={styles.bg}></div>
            <div className={styles.form_container}>
              <div className={styles.FormBox}>
                <form className="row g-3" onSubmit={handleSubmit}>
                  <div className="col-md-6">
                    <label className={styles.name}>First Name :</label>
                    <input
                      type="text"
                      className="form-control"
                      id="text"
                      required
                      name="firstName"
                      value={data.firstName}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="col-md-6">
                    <label className={styles.name}>Last Name :</label>
                    <input
                      type="text"
                      className="form-control"
                      style={{ border: "0", height: "40px" }}
                      id="text"
                      name="lastName"
                      value={data.lastName}
                      onChange={handleChange}
                      required
                    />
                  </div>

                  <div className="col-md-6">
                    <label className={styles.name}>Company Name :</label>
                    <input
                      type="text"
                      className="form-control"
                      style={{ border: "0", height: "40px" }}
                      id="text"
                      placeholder="Optional"
                      name="companyName"
                      value={data.companyName}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="col-md-6">
                    <label className={styles.name}>Phone Number :</label>
                    <input
                      type="text"
                      className="form-control"
                      style={{ border: "0", height: "40px" }}
                      id="text"
                      name="phoneNumber"
                      value={data.phoneNumber}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-12">
                    <label className={styles.formlabel3}>Email Id :</label>
                    <input
                      type="email"
                      className="form-control"
                      style={{ border: "0", height: "40px" }}
                      id="email"
                      name="email"
                      value={data.email}
                      onChange={handleChange}
                      required
                    />
                  </div>

                  <div className="col-12">
                    <label className={styles.formlabel}>Address :</label>
                    <input
                      type="text"
                      className={styles.formcontrol}
                      id="inputAddress"
                      placeholder="Street, Apartment, or floor"
                      name="address"
                      value={data.address}
                      onChange={handleChange}
                      required
                    />
                  </div>

                  <div className="col-12">
                    <label className={styles.formlabel2}>Purpose :</label>
                    <input
                      type="text"
                      className={styles.formcontrol3}
                      id="inputAddress"
                      placeholder="State your purpose : Demo , Collaboration , etc. "
                      name="purpose"
                      value={data.purpose}
                      onChange={handleChange}
                      required
                    />
                  </div>

                  <div className={styles.threetier}>
                    <div className="col-md-3">
                      <label className={styles.formlabel}>City :</label>
                      <input
                        type="text"
                        className={styles.formcontrol1}
                        id="inputCity"
                        name="city"
                        value={data.city}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="col-md-3">
                      <label className={styles.formlabel}>State :</label>
                      <select
                        id="inputState"
                        className="form-select"
                        style={{ border: "0", height: "40px" }}
                        name="state"
                        value={data.state}
                        onChange={handleChange}
                      >
                        <option value="" selected disabled>
                          Choose State
                        </option>
                        <option value="Andhra Pradesh">Andhra Pradesh</option>
                        <option value="Arunachal Pradesh">
                          Arunachal Pradesh
                        </option>
                        <option value="Assam">Assam</option>
                        <option value="Bihar">Bihar</option>
                        <option value="Chhattisgarh">Chhattisgarh</option>
                        <option value="Goa">Goa</option>
                        <option value="Gujarat">Gujarat</option>
                        <option value="Haryana">Haryana</option>
                        <option value="Himachal Pradesh">
                          Himachal Pradesh
                        </option>
                        <option value="Jharkhand">Jharkhand</option>
                        <option value="Karnataka">Karnataka</option>
                        <option value="Kerala">Kerala</option>
                        <option value="Madhya Pradesh">Madhya Pradesh</option>
                        <option value="Maharashtra">Maharashtra</option>
                        <option value="Manipur">Manipur</option>
                        <option value="Meghalaya">Meghalaya</option>
                        <option value="Mizoram">Mizoram</option>
                        <option value="Nagaland">Nagaland</option>
                        <option value="Odisha">Odisha</option>
                        <option value="Punjab">Punjab</option>
                        <option value="Rajasthan">Rajasthan</option>
                        <option value="Sikkim">Sikkim</option>
                        <option value="Tamil Nadu">Tamil Nadu</option>
                        <option value="Telangana">Telangana</option>
                        <option value="Tripura">Tripura</option>
                        <option value="Uttar Pradesh">Uttar Pradesh</option>
                        <option value="Uttarakhand">Uttarakhand</option>
                        <option value="West Bengal">West Bengal</option>
                      </select>
                    </div>

                    <div className="col-md-3">
                      <label className={styles.formlabel}>Zip :</label>
                      <input
                        type="text"
                        className={styles.formcontrol1}
                        id="inputZip"
                        name="zip"
                        value={data.zip}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className={styles.button__container}>
                      <div
                        className=""
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        <button
                          type="submit"
                          className={styles.button}
                          onClick={goBack}
                        >
                          GO BACK
                        </button>
                      </div>
                      <div className="">
                        <button type="submit" className={styles.button2}>
                          SUBMIT
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* ------------------------Small and Mobile Devices------------------------------------ */}
      <div className={styles.Potraitview}>
        <div className={styles.bgContainer}>
          <img
            src={`${process.env.PUBLIC_URL}/ContactForm-Bg/Mobile/BG.png`}
            alt="Title_icon"
            className={styles.bgimage}
          />
        </div>
        <div className={styles.firstbox}>
          <img
            src={`${process.env.PUBLIC_URL}/ContactForm-Bg/Mobile/png1.png`}
            alt="Title_icon"
            className={styles.bgimage2}
          />
        </div>
        <div className={styles.secondbox}>
          <div style={{ position: "relative" }}>
            <ToastContainer />
            {loader && (
              <div className={styles.loaderContainer}>
                <Loader
                  type="spinner-cub"
                  bgColor={"rgba(0, 0, 0, 0.8)"}
                  color={"white"}
                  title={"Please Wait"}
                  size={100}
                />
              </div>
            )}

            <div className={styles.form_container}>
              <div className={styles.FormBox}>
                <form className="row g-3" onSubmit={handleSubmit}>
                  <div className="col-md-6">
                    <label className={styles.name}>First Name :</label>
                    <input
                      type="text"
                      className="form-control"
                      id="text"
                      required
                      name="firstName"
                      value={data.firstName}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="col-md-6">
                    <label className={styles.name}>Last Name :</label>
                    <input
                      type="text"
                      className="form-control"
                      style={{ border: "0", height: "40px" }}
                      id="text"
                      name="lastName"
                      value={data.lastName}
                      onChange={handleChange}
                      required
                    />
                  </div>

                  <div className="col-md-6">
                    <label className={styles.name}>Company Name :</label>
                    <input
                      type="text"
                      className="form-control"
                      style={{ border: "0", height: "40px" }}
                      id="text"
                      placeholder="Optional"
                      name="companyName"
                      value={data.companyName}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="col-md-6">
                    <label className={styles.name}>Phone Number :</label>
                    <input
                      type="text"
                      className="form-control"
                      style={{ border: "0", height: "40px" }}
                      id="text"
                      name="phoneNumber"
                      value={data.phoneNumber}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-12">
                    <label className={styles.formlabel3}>Email Id :</label>
                    <input
                      type="email"
                      className="form-control"
                      style={{ border: "0", height: "40px" }}
                      id="email"
                      name="email"
                      value={data.email}
                      onChange={handleChange}
                      required
                    />
                  </div>

                  <div className="col-12">
                    <label className={styles.formlabel}>Address :</label>
                    <input
                      type="text"
                      className={styles.formcontrol}
                      id="inputAddress"
                      placeholder="Street, Apartment, or floor"
                      name="address"
                      value={data.address}
                      onChange={handleChange}
                      required
                    />
                  </div>

                  <div className="col-12">
                    <label className={styles.formlabel2}>Purpose :</label>
                    <input
                      type="text"
                      className={styles.formcontrol3}
                      id="inputAddress"
                      placeholder="State your purpose : Demo , Collaboration , etc. "
                      name="purpose"
                      value={data.purpose}
                      onChange={handleChange}
                      required
                    />
                  </div>

                  <div className={styles.threetier}>
                    <div className="col-md-3">
                      <label className={styles.formlabel}>City :</label>
                      <input
                        type="text"
                        className={styles.formcontrol1}
                        id="inputCity"
                        name="city"
                        value={data.city}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="col-md-3">
                      <label className={styles.formlabel}>State :</label>
                      <select
                        id="inputState"
                        className="form-select"
                        style={{ border: "0", height: "40px" }}
                        name="state"
                        value={data.state}
                        onChange={handleChange}
                      >
                        <option value="" selected disabled>
                          Choose State
                        </option>
                        <option value="Andhra Pradesh">Andhra Pradesh</option>
                        <option value="Arunachal Pradesh">
                          Arunachal Pradesh
                        </option>
                        <option value="Assam">Assam</option>
                        <option value="Bihar">Bihar</option>
                        <option value="Chhattisgarh">Chhattisgarh</option>
                        <option value="Goa">Goa</option>
                        <option value="Gujarat">Gujarat</option>
                        <option value="Haryana">Haryana</option>
                        <option value="Himachal Pradesh">
                          Himachal Pradesh
                        </option>
                        <option value="Jharkhand">Jharkhand</option>
                        <option value="Karnataka">Karnataka</option>
                        <option value="Kerala">Kerala</option>
                        <option value="Madhya Pradesh">Madhya Pradesh</option>
                        <option value="Maharashtra">Maharashtra</option>
                        <option value="Manipur">Manipur</option>
                        <option value="Meghalaya">Meghalaya</option>
                        <option value="Mizoram">Mizoram</option>
                        <option value="Nagaland">Nagaland</option>
                        <option value="Odisha">Odisha</option>
                        <option value="Punjab">Punjab</option>
                        <option value="Rajasthan">Rajasthan</option>
                        <option value="Sikkim">Sikkim</option>
                        <option value="Tamil Nadu">Tamil Nadu</option>
                        <option value="Telangana">Telangana</option>
                        <option value="Tripura">Tripura</option>
                        <option value="Uttar Pradesh">Uttar Pradesh</option>
                        <option value="Uttarakhand">Uttarakhand</option>
                        <option value="West Bengal">West Bengal</option>
                      </select>
                    </div>

                    <div className="col-md-3">
                      <label className={styles.formlabel}>Zip :</label>
                      <input
                        type="text"
                        className={styles.formcontrol1}
                        id="inputZip"
                        name="zip"
                        value={data.zip}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className={styles.button__container}>
                      <div
                        className=""
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        <button
                          type="submit"
                          className={styles.button}
                          onClick={goBack}
                        >
                          GO BACK
                        </button>
                      </div>
                      <div className="">
                        <button type="submit" className={styles.button2}>
                          SUBMIT
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactForm;
